import { useEffect, useState, useMemo } from "react";
import moment from "moment-timezone";

import "./styles.css";

export default function App() {
  const timezone = useMemo(() => moment.tz.guess(), []);

  const [now, setNow] = useState(Date.now());
  const date = new Date(now);

  const getTime = (): string => {
    return date.toLocaleString(navigator.language, {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };

  const getDate = (): string => {
    return date.toLocaleString(navigator.language, {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  useEffect(() => {
    setInterval(() => {
      setNow(Date.now());
    }, 1000);
  }, []);

  return (
    <div className="App">
      <div className="header">
        <span>To:</span> <div contentEditable>Nnedimkpa</div>
      </div>
      <div className="message">
        <p contentEditable>Hello!</p>
      </div>
      <div className="footer">
        <span>From:</span> <div contentEditable>Richard</div>
      </div>
      <div className="metadata">
        <span>{getTime()}</span>
        <span>{timezone}</span>
        <span>{getDate()}</span>
      </div>
    </div>
  );
}
